import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ReCAPTCHA from 'react-google-recaptcha';
import { accountService } from '../../Services';

import { useSearchParams } from 'react-router-dom'
import {
  HeaderAcc,
  Toast,
  EmailInput,
} from '../../Components';
const initForm = {
  email: "",
};

export default function ForgotPass({ setModal, setRoleId }) {
  const [form, setForm] = useState(initForm);
  const [error, setError] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [formSubmit, setFormSubmit] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [showlinkExpiredMessage, setShowlinkExpiredMessage] = useState(searchParams.get('expired'));
  const [captcha, setCaptcha] = useState('');
  const navigate = useNavigate();

  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  useEffect(() => {
    if (showlinkExpiredMessage == 1) {
      addToast({
        error: true,
        msg: "The link is no longer valid. Please try to reset your password.",
      });
    }
  }, [])
  const submitEmail = async (payload) => {
    try {
      let res = await accountService
        .forgotPassworkCheck(payload)
        .then(res => {
          //console.log(res);
          addToast({
            error: false,
            msg: res.message,
            date: new Date()
          });
          setFormSubmit(true)
        })
        .catch(err => {
          addToast({
            error: true,
            msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
            successText: '',
            date: new Date()
          });
        });

    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err.message });
    }
  };
  const createValidation = () => {
    if (form.email === '' || captcha === '') {
      setError(true);
    } else {
      const payload = {
        email: form.email,
        captcha: captcha
      };
      submitEmail(payload)
    }
  }
  const onChange = value => {
    setCaptcha(value);
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        createValidation();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);
  return (
    <>
      <div className='account log' >
        <div className='account__inner'>
          <HeaderAcc />
          <div className='account__inner-col'>
            <div className='account__inner-col-image'>
              <img
                src={process.env.PUBLIC_URL + '/images/accountBg.jpg'}
                alt='accountBg'
              />
            </div>
          </div>
          <div className="account__footer">
            <div className="account__footer-inner">
              <div className="account__footer-copy">Copyright {new Date().getFullYear()} © TamaYou</div>
              <Link target="_blank" to="https://tamayou.com/tama-privacy-policy/" className="account__footer-link">
                Privacy
              </Link>
              <Link target="_blank" to="https://tamayou.com/terms/" className="account__footer-link linkTerms">
                Terms
              </Link>
            </div>
          </div>
          <div className='account__inner-col'>
            <motion.div
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              exit={{ opacity: 0, y: 0 }}
              className='account__login'
            >
              <div className='account__login-inner'>
                <h2 className='uniq'>Forgot password</h2>
                {formSubmit === true ?
                  <p className="emailSentMessage">If {form.email} exists, we have sent you a
                    password reset email.</p>
                  : <>
                    <button
                      className='button login'
                      onClick={() => setModal('platform')}
                    >
                      Log in with
                      <img
                        src={process.env.PUBLIC_URL + '/images/googleSign.png'}
                        alt=''
                      />
                      <img
                        src={process.env.PUBLIC_URL + '/images/facebookSign.png'}
                        alt=''
                      />
                      <img
                        src={process.env.PUBLIC_URL + '/images/tiktokSign.png'}
                        alt=''
                      />
                      <img
                        src={process.env.PUBLIC_URL + '/images/instagramSign.png'}
                        alt=''
                      />
                    </button>
                    <h5 className='reg'>or</h5>
                    <div className='account__create-email'>
                      <EmailInput
                        value={form.email}
                        type='email'
                        onChange={setInput('email')}
                        label='E-mail'
                        error={error}
                        errorText='Please enter Email'
                        autoFocus
                      />
                    </div>
                    <div className='account__create-rect'>
                      <ReCAPTCHA
                        sitekey='6Ledv1skAAAAAF3Zb07lBukAj9N-sfq5wA_C7872'
                        onChange={onChange}
                      />
                    </div>
                    {error && captcha == "" &&
                      <div className="input__error captchaerror">Please select captcha</div>}
                    <button className='button primary' onClick={createValidation}>
                      Send Password reset link
                    </button>
                  </>
                }
                <p className='sm'>
                  Click Here to {' '}
                  <Link to='/account/login'>Sign in</Link>
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      {
        toastList && <Toast list={toastList} setList={setToastList} />
      }
    </>
  );
}